/* eslint-disable */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { disconnectQuickBooks } from "../../../../services/quickbooks/quickBooksService";

// Import your custom components
import Section from "../../../../components/Section";
import ContentBlock from "../../../../components/ContentBlock";
import CustomBlock from "../../../../components/CustomBlock";
import ContentHeader from "../../../../components/ContentHeader";
import Button from "../../../../components/Button";
import InfoBlock from "../../../../components/InfoBlock";
import Overlay from "../../../../components/Overlay";

const QuickBooksDisconnectPage = () => {
  const history = useHistory();
  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const handleDisconnect = async () => {
    try {
      setIsDisconnecting(true);
      const response = await disconnectQuickBooks();
      localStorage.removeItem("quickbooks_state");
      alert("Successfully disconnected from QuickBooks");
      history.push("/dashboard");
    } catch (err) {
      console.error("Error disconnecting from QuickBooks:", err);
      alert("Failed to disconnect from QuickBooks. Please try again.");
    } finally {
      setIsDisconnecting(false);
    }
  };

  return (
    <CustomBlock className="content-container--padded">
      {isDisconnecting && <Overlay hasLoader />}
      
      <Section isFullWidth>
        <ContentBlock>
          <CustomBlock className="content-container--card-style--with-shadow">
            <ContentHeader
              title="Disconnect QuickBooks"
              headerSize="lg"
            />

            <Section hasNoContainer>
              <ContentBlock>
                <InfoBlock isCentered type="info">
                  <p>Are you sure you want to disconnect your QuickBooks account?</p>
                  <p className="mt-2">This action will remove access to your QuickBooks data.</p>
                </InfoBlock>
              </ContentBlock>

              <ContentBlock className="content-container--actions flex-end mt-4">
                <Button
                  text="Cancel"
                  className="secondary--bg mr-2"
                  onClick={() => history.push("/dashboard")}
                  disabled={isDisconnecting}
                />
                <Button
                  text={isDisconnecting ? "Disconnecting..." : "Disconnect"}
                  className="danger--bg"
                  onClick={handleDisconnect}
                  disabled={isDisconnecting}
                  isLoading={isDisconnecting}
                />
              </ContentBlock>
            </Section>
          </CustomBlock>
        </ContentBlock>
      </Section>
    </CustomBlock>
  );
};

export default QuickBooksDisconnectPage;