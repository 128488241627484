/* eslint-disable */
import React from "react";
import Section from "../../components/Section";
import ContentBlock from "../../components/ContentBlock";

const EulaPage = () => {
    const styles = {
      mainWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'auto'
      },
      pageWrapper: {
        width: '100%',
        minHeight: '100%',
        backgroundColor: '#f5f5f5',
        paddingBottom: '40px'
      },
      container: {
        maxWidth: "800px",
        margin: "0 auto",
        lineHeight: "1.6",
        fontFamily: "Arial, sans-serif",
        color: "#333",
        padding: "20px",
        backgroundColor: '#ffffff',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        borderRadius: '8px',
      },
      title: {
        fontSize: "2.5rem",
        color: "#007bff",
        marginBottom: "20px",
        textAlign: "center",
      },
      sectionTitle: {
        fontSize: "1.8rem",
        color: "#0056b3",
        marginTop: "30px",
        marginBottom: "10px",
        borderBottom: "2px solid #ddd",
        paddingBottom: "5px",
      },
      paragraph: {
        fontSize: "1rem",
        marginBottom: "15px",
      },
      list: {
        paddingLeft: "20px",
        marginBottom: "15px",
      },
      listItem: {
        listStyleType: "disc",
        marginBottom: "10px",
      },
      consentText: {
        fontSize: "1rem",
        fontStyle: "italic",
        marginTop: "20px",
        color: "#555",
      },
    };
  
    return (
      <div style={styles.mainWrapper}>
        <div style={styles.pageWrapper}>
          <Section>
            <ContentBlock style={styles.container}>
              <h1 style={styles.title}>End-User License Agreement (EULA)</h1>
              <p style={styles.paragraph}>
                This End-User License Agreement is a legal agreement between you
                ("User") and <strong>Primtek</strong> governing your use of the
                Capital Area Groundwater Conservation Commission (CAGWCC) application.
              </p>
    
              <div>
                <h2 style={styles.sectionTitle}>1. License Grant</h2>
                <p style={styles.paragraph}>
                  Provider grants you a non-exclusive, non-transferable, revocable license to use the
                  Application for pushing customers and invoices to QBO, and syncing data with
                  QuickBooks Online (QBO). This license is granted for the term of your agreement
                  with CAGWCC and is subject to compliance with this Agreement.
                </p>
              </div>
    
              <div>
                <h2 style={styles.sectionTitle}>2. Restrictions</h2>
                <p style={styles.paragraph}>
                  You may not:
                </p>
                <ul style={styles.list}>
                  <li style={styles.listItem}>Modify, reverse-engineer, or decompile the Application.</li>
                  <li style={styles.listItem}>Use the Application to access unauthorized data.</li>
                  <li style={styles.listItem}>
                    Share login credentials or allow unauthorized users access to the Application.
                  </li>
                </ul>
              </div>
    
              <div>
                <h2 style={styles.sectionTitle}>3. Data Management</h2>
                <p style={styles.paragraph}>
                  The Application facilitates the transmission of invoice and customer data to QBO.
                  The User agrees to provide accurate data and is responsible for the accuracy of
                  invoices and related details synced with QBO.
                </p>
              </div>
    
              <div>
                <h2 style={styles.sectionTitle}>4. Ownership</h2>
                <p style={styles.paragraph}>
                  The Application and all related intellectual property rights are owned by
                  Primtek. This Agreement does not transfer any ownership rights to the User.
                </p>
              </div>
    
              <div>
                <h2 style={styles.sectionTitle}>5. Liability Disclaimer</h2>
                <p style={styles.paragraph}>
                  The Application is provided "as is" without warranties of any kind. Primtek is
                  not liable for errors resulting from incorrect data input by the User, disruptions
                  in API communication with QBO, or third-party system failures.
                </p>
              </div>
    
              <div>
                <h2 style={styles.sectionTitle}>6. Termination</h2>
                <p style={styles.paragraph}>
                  This Agreement is effective until terminated. Termination occurs automatically if
                  the User violates any terms of this Agreement. Upon termination, the User must
                  discontinue all use of the Application.
                </p>
              </div>
    
              <div>
                <h2 style={styles.sectionTitle}>7. Governing Law</h2>
                <p style={styles.paragraph}>
                  This Agreement is governed by the laws of the State of Louisiana. Disputes arising
                  under this Agreement shall be resolved in accordance with these laws.
                </p>
              </div>
    
              <p style={styles.consentText}>
                By using the Application, you acknowledge that you have read, understood, and agreed
                to the terms outlined in this Agreement.
              </p>
            </ContentBlock>
          </Section>
        </div>
      </div>
    );
};

export default EulaPage;