/* eslint-disable */
import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Configuration/';

const getClientConfig = async () => 
  axiosInstance.get(`${ENDPOINTORIGIN}GetClientConfig`, constants.REQUIRE_INTERCEPTORS);

const getStripeConfig = async () => 
  axiosInstance.get(`${ENDPOINTORIGIN}GetStripeConfig`, constants.REQUIRE_INTERCEPTORS);

const getQuickBooksConfig = async () => 
  axiosInstance.get(`${ENDPOINTORIGIN}GetQuickBooksConfig`, constants.REQUIRE_INTERCEPTORS);

export {
  getClientConfig,
  getStripeConfig,
  getQuickBooksConfig
};