/* eslint-disable */
import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'QuickBooks/';

const exchangeToken = async (model) => {
  return axiosInstance.post(`${ENDPOINTORIGIN}ExchangeToken`, model, constants.REQUIRE_INTERCEPTORS);
};

const disconnectQuickBooks = async () => {
  return axiosInstance.post(`${ENDPOINTORIGIN}Disconnect`, null, constants.REQUIRE_INTERCEPTORS);
};

export { exchangeToken, disconnectQuickBooks };