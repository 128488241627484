/* eslint-disable */
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// Import your custom components
import Section from "../../../../components/Section";
import ContentBlock from "../../../../components/ContentBlock";
import CustomBlock from "../../../../components/CustomBlock";
import ContentHeader from "../../../../components/ContentHeader";
import Button from "../../../../components/Button";
import InfoBlock from "../../../../components/InfoBlock";
import { getQuickBooksConfig } from '../../../../services/configuration/configService';

const QuickBooksAuthPage = () => {
  const [config, setConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await getQuickBooksConfig();
        setConfig(response);
      } catch (error) {
        console.error('Error fetching QuickBooks config:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfig();
  }, []);

  if (isLoading || !config) return <div>Loading...</div>;

  const handleGenerateUrl = () => {
    const state = uuidv4();
    localStorage.setItem("quickbooks_state", state);
    localStorage.setItem("quickbooks_environment", config.environment);
    const authUrl = new URL("https://appcenter.intuit.com/connect/oauth2");
    authUrl.searchParams.append("client_id", config.clientId);
    authUrl.searchParams.append("redirect_uri", config.redirectUri);
    authUrl.searchParams.append("response_type", "code");
    authUrl.searchParams.append("scope", "com.intuit.quickbooks.accounting");
    authUrl.searchParams.append("state", state);

    window.location.href = authUrl.toString();
  };

  return (
    <CustomBlock className="content-container--padded">
      <Section isFullWidth>
        <ContentBlock>
          <CustomBlock className="content-container--card-style--with-shadow">
            <ContentHeader
              title="QuickBooks Authorization"
              headerSize="lg"
            />

            <Section hasNoContainer>
              <ContentBlock>
                <InfoBlock isCentered>
                  <p>Connect your QuickBooks account to sync your financial data.</p>
                  <p className="mt-2 text-muted">
                    This will allow secure access to your QuickBooks information.
                  </p>
                </InfoBlock>

                <CustomBlock className="mt-4">
                  <div className="d-flex justify-content-center">
                    <Button
                      text="Connect to QuickBooks"
                      className="primary--bg"
                      onClick={handleGenerateUrl}
                      icon={
                        <svg 
                          className="mr-2" 
                          width="20" 
                          height="20" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="currentColor" 
                          strokeWidth="2"
                        >
                          <path d="M20 6L9 17l-5-5"/>
                        </svg>
                      }
                    />
                  </div>
                </CustomBlock>

                <CustomBlock className="mt-4">
                  <InfoBlock type="info" isCentered>
                    <p className="text-sm">
                      You will be redirected to QuickBooks to complete the authorization process.
                    </p>
                  </InfoBlock>
                </CustomBlock>
              </ContentBlock>
            </Section>
          </CustomBlock>
        </ContentBlock>
      </Section>
    </CustomBlock>
  );
};

export default QuickBooksAuthPage;