/* eslint-disable */
import React from "react";
import Section from "../../components/Section";
import ContentBlock from "../../components/ContentBlock";

const PrivacyPolicyPage = () => {
    const styles = {
          mainWrapper: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'auto'
          },
          pageWrapper: {
            width: '100%',
            minHeight: '100%',
            backgroundColor: '#f5f5f5',
            paddingBottom: '40px'
          },
          container: {
            maxWidth: "800px",
            margin: "0 auto",
            lineHeight: "1.6",
            fontFamily: "Arial, sans-serif",
            color: "#333",
            padding: "20px",
            backgroundColor: '#ffffff',
            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
            borderRadius: '8px',
          },
          title: {
            fontSize: "2.5rem",
            color: "#007bff",
            marginBottom: "20px",
            textAlign: "center",
          },
          sectionTitle: {
            fontSize: "1.8rem",
            color: "#0056b3",
            marginTop: "30px",
            marginBottom: "10px",
            borderBottom: "2px solid #ddd",
            paddingBottom: "5px",
          },
          paragraph: {
            fontSize: "1rem",
            marginBottom: "15px",
          },
          list: {
            paddingLeft: "20px",
            marginBottom: "15px",
          },
          listItem: {
            listStyleType: "disc",
            marginBottom: "10px",
          },
          link: {
            color: "#007bff",
            textDecoration: "none",
          },
          consentText: {
            marginTop: "30px",
            fontStyle: "italic",
            color: "#666",
          }
        };
      
        return (
          <div style={styles.mainWrapper}>
            <div style={styles.pageWrapper}>
              <div style={styles.container}>
                <h1 style={styles.title}>Privacy Policy</h1>
      
                <p style={{ ...styles.paragraph, fontSize: "1.1rem", color: "#555" }}>
                  At <strong>Primtek</strong>, we are committed to protecting the privacy of our users.
                  This Privacy Policy outlines how data is collected, used, and safeguarded by the
                  Capital Area Groundwater Conservation Commission (CAGWCC) application.
                </p>
      
                <div>
                  <h2 style={styles.sectionTitle}>1. Data Collection</h2>
                  <p style={styles.paragraph}>The Application collects the following data:</p>
                  <ul style={styles.list}>
                    <li style={styles.listItem}>Invoice data to sync to QBO.</li>
                    <li style={styles.listItem}>
                      Customer information (e.g., name, address) necessary for syncing with QuickBooks
                      Online (QBO).
                    </li>
                    <li style={styles.listItem}>
                      Operational data such as adjustments, pumpage, and late fees for invoicing.
                    </li>
                  </ul>
                </div>
      
                <div>
                  <h2 style={styles.sectionTitle}>2. Data Usage</h2>
                  <p style={styles.paragraph}>The data collected is used for the following purposes:</p>
                  <ul style={styles.list}>
                    <li style={styles.listItem}>Creating and syncing invoices with QBO via the QuickBooks API.</li>
                    <li style={styles.listItem}>Processing payments and adjustments.</li>
                    <li style={styles.listItem}>Generating compliance and financial reports.</li>
                  </ul>
                  <p style={styles.paragraph}>
                    <em>No data is sold or shared with third parties except for integration with QBO as
                    specified.</em>
                  </p>
                </div>
      
                <div>
                  <h2 style={styles.sectionTitle}>3. Data Security</h2>
                  <p style={styles.paragraph}>
                    We implement industry-standard encryption and security practices to protect your data.
                    All communication with QBO is secured via OAuth 2.0 and HTTPS. Sensitive data, such as
                    API tokens, is encrypted and stored securely in our database.
                  </p>
                </div>
      
                <div>
                  <h2 style={styles.sectionTitle}>4. Data Sharing</h2>
                  <p style={styles.paragraph}>
                    The Application only shares data with QBO via its API to facilitate synchronization of
                    invoices, customers, and related financial information. <strong>No data is shared with other
                    third parties.</strong>
                  </p>
                </div>
      
                <div>
                  <h2 style={styles.sectionTitle}>5. User Rights</h2>
                  <p style={styles.paragraph}>Users can:</p>
                  <ul style={styles.list}>
                    <li style={styles.listItem}>Request access to their data stored in the Application.</li>
                    <li style={styles.listItem}>
                      Revoke the Application's access to QBO at any time through QuickBooks settings.
                    </li>
                    <li style={styles.listItem}>
                      Request data deletion by contacting{" "}
                      <a href="mailto:aadhar@primtek.com" style={styles.link}>
                      aadhar@primtek.com
                      </a>
                    </li>
                  </ul>
                </div>
      
                <div>
                  <h2 style={styles.sectionTitle}>6. Retention Policy</h2>
                  <p style={styles.paragraph}>
                    Data related to invoices, and adjustments is retained for auditing and
                    compliance purposes. Once a user terminates their agreement, data will be retained for
                    a period specified by applicable laws before deletion.
                  </p>
                </div>
      
                <div>
                  <h2 style={styles.sectionTitle}>7. Contact Information</h2>
                  <p style={styles.paragraph}>For questions or concerns about this Privacy Policy, contact us at:</p>
                  <p style={styles.paragraph}>
                    <strong>Email:</strong>{" "}
                    <a href="mailto:aadhar@primtek.com" style={styles.link}>
                    aadhar@primtek.com
                    </a>
                  </p>
                  <p style={styles.paragraph}>
                    <strong>Phone:</strong> (225) 304-0614
                  </p>
                </div>
      
                <p style={styles.consentText}>
                  By using the Application, you consent to the collection and use of data as outlined in
                  this Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        );
};

export default PrivacyPolicyPage;