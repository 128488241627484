/* eslint-disable */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { exchangeToken } from "../../../../services/quickbooks/quickBooksService";

const QuickBooksCallback = () => {
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    const realmId = urlParams.get("realmId");
    const storedState = localStorage.getItem("quickbooks_state");
    const environment = localStorage.getItem("quickbooks_environment");

    // Validate the state parameter
    if (!state || state !== storedState) {
      console.error("Invalid or missing state parameter");
      alert("Authorization failed due to invalid state. Please try again.");
      history.push("/dashboard");
      return;
    }

    // Ensure both code and realmId are present
    if (code && realmId) {
      exchangeToken({ code, realmId, environment })
        .then((response) => {
          console.log("Tokens saved successfully:", response.data);
          alert("QuickBooks integration successful!");
          history.push("/dashboard");
        })
        .catch((err) => {
          console.error("Error exchanging token:", err);
          alert("Failed to integrate QuickBooks. Please try again.");
          history.push("/dashboard");
        });
    } else {
      console.error("Authorization failed: Missing code or realmId");
      alert("Authorization failed. Please try again.");
      history.push("/dashboard");
    }
  }, [history]);

  return (
    <div>
      <h2>Processing QuickBooks Authorization...</h2>
    </div>
  );
};

export default QuickBooksCallback;